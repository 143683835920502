import React from "react"
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../src/constants/Theme';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link href="https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.typekit.net/buo7tbq.css"/>
        {
          process.env.NODE_ENV === "development" &&
          <link rel="stylesheet" href="https://i.icomoon.io/public/ecd6936e48/idl-gatsby/style.css"/>
        }
        {
          process.env.NODE_ENV === "production" &&
          <link rel="stylesheet" href="https://d1azc1qln24ryf.cloudfront.net/29147/idl-gatsby/style-cf.css?g6bdyf" />
        }
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};