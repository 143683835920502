import { createTheme } from "@material-ui/core"
import Colors from "./Colors"

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontWeight: "900",
      fontSize: "60px",
      marginBottom: "30px !important",
      lineHeight: 1.1,
      color: Colors.pickledBluewood,
      "@media (max-width: 600px)":{
        fontSize: 38,
        lineHeight: "42px !important",
        marginBottom: "24px !important",
      },
      "&.inverted": {
        color: Colors.inverted
      },
    },
    h2: {
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontWeight: "900",
      fontSize: 38,
      color: Colors.pickledBluewood,
      lineHeight: "46px",
      marginBottom: "28px",
      "@media (max-width: 600px)":{
        fontSize: 24,
        lineHeight: "30px",
        marginBottom: 20,
      },
      "&.inverted": {
        color: Colors.inverted
      },
    },
    h3: {
      //width: "163px",
      color: Colors.tundora,
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontSize: "18px",
      fontWeight: 900,
      lineHeight: "22px",
      marginBottom: 12,
    },
    h4: {
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontSize: "16px",
      fontWeight: 900,
      lineHeight: "19px",
      color: Colors.grayChateau,
    },
    h5: {
      color: Colors.kashmirBlue,
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "15px",
      textTransform: "uppercase",
      marginBottom: "7px !important",
    },
    h6: {
      color: Colors.pickledBluewood,
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "18px",
      marginBottom: "7px !important",
      '&.MuiTypography-gutterBottom': {
        marginBottom: "7px !important",
      },
      "@media screen and (max-width: 600px)": {
        marginBottom: 20,
      },
    },
    subtitle1: {
      color: Colors.pickledBluewood,
      fontFamily: "Calluna",
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "26px",
      marginBottom: "10px !important",
    },
    body1: {
      color: Colors.mineShaft,
      fontFamily: "Calluna",
      fontSize: "16px",
      lineHeight: "26px",
      marginBottom: "7px !important",
    },
    button: {
      fontFamily: "Lato, Verdana, Arial, sans-serif",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "17px",
      textAlign: "center",
      textTransform: "none",
      textDecoration: "none",
    },
    blockquote: {
      fontFamily: 'Calluna, Georgia, Serif',
      fontStyle: 'italic',
      fontWeight: 'bold',
      fontSize: 18,
      color: Colors.scorpion,
      marginTop: 20,
      marginBottom: 20,
      padding: '12px 20px',
      borderLeft: `4px solid ${Colors.oliveGreen}`,
    },
    inverted: {
      color: Colors.inverted
    }
  },
  //Other values: breakpoints, spacing, z-index, globals
})


export default theme;