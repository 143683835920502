/*
Mirrors vars found in src/components/layout.css.
Please add the color there as well if you update this.
 */

export default {
  tulipTree: '#E89944',
  brandyPunch: '#D68630',
  lynch: '#7183A0',
  kashmirBlue: "#4E6489",
  azure: '#2E5AAB',
  pickledBluewood: '#2F4259',
  grayChateau: '#98A0A6',
  mineShaft: "#333333",
  tundora: "#4a4a4a",
  scorpion: "#565656",
  white: "#FFFFFF",
  inverted: "#FFFFFF",
  coconutCream: "rgba(242,247,211,0.9)",
  catskillWhite: "#EFF3F7",
  mystic: "#E4E8EE",
  athensGray: "#E4E8ED",
  horizontalUnderlineGray: "#E1E4EA",
  loblolly: "#C5CDD1",
  oliveGreen: "#9EAB51",
  errorBg: "#FFF0EE",
}
