/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'mapbox-gl/dist/mapbox-gl.css'
import {globalHistory} from '@reach/router';


const scrollToHash = (id) => {
  const el = document.querySelector(id);
  const focusWrapper = document.getElementById('gatsby-focus-wrapper');
  if(el){
    if(focusWrapper.scrollTo !== undefined){
      return document.getElementById('gatsby-focus-wrapper').scrollTo(0, el.offsetTop - 40);
    } else {
      //edge case for edge browser
      return document.getElementById('gatsby-focus-wrapper').scrollTop = el.offsetTop - 40;
    }
  }
  return false;
}

export const onRouteUpdate = ({location: {hash}}) => {
  if (typeof window !== 'undefined') {
    if(hash){
     window.setTimeout( scrollToHash(hash), 10)
    } else {
      if(document.getElementById('gatsby-focus-wrapper').scrollTo !== undefined){
        document.getElementById('gatsby-focus-wrapper').scrollTo(0, 0)
      } else {
        document.getElementById('gatsby-focus-wrapper').scrollTop = 0;
      }
    }
  }
}

export const shouldUpdateScroll = args => {
  //  scroll on < back, forward >, refresh, but not link clicks
  return (
    args.prevRouterProps == null || !(args.prevRouterProps.location.pathname === args.routerProps.location.pathname)
  ) ? true : false;};
